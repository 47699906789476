import { createContext, useContext, useState } from "react";
const GlobalContext = createContext();
export const useGlobalContext = () => useContext(GlobalContext);

const AppContext = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isRecuperar, setIsRecuperar] = useState(false);

  const [usuario, setUsuario] = useState();

  const headerText = new Headers();

  headerText.append("Content-Type", "application/json;charset=UTF-8");

  //Comentar para beta
  const [baseUrl, setBaseUrl] = useState(
  "https://documentos.kesp.cl/documentos.service.beta/rest/"
  );

  //Comentar para local
  //const [baseUrl, setBaseUrl] = useState(
  //  "http://localhost:8080/facturador.service.rest/rest/"
  //);

  // backgroundColor: "#007ad9"
  const [styleProperties, setStyleProperties] = useState({
    logo: "assets/layout/images/Recurso-2.png",
    title: "Documentos",
    backgroundColor: "grey",
    button: {
      backgroundColor: "#20ba84",
      foreColor: "#fff",
      border: "1px solid #007ad9",
    },
  });

  return (
    <GlobalContext.Provider
      value={{
        baseUrl,
        setBaseUrl,
        styleProperties,
        setStyleProperties,
        isLoggedIn,
        setIsLoggedIn,
        usuario,
        setUsuario,
        isRecuperar,
        setIsRecuperar,
        headerText,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
