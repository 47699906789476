import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { useGlobalContext } from "../Config";
import { FileUpload } from "primereact/fileupload";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Dialog } from "primereact/dialog";
import "jspdf-autotable";

const Documentos = (props) => {
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const { styleProperties } = useGlobalContext();

  const [empresa, setEmpresa] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [anio, setAnio] = useState(null);
  const [mes, setMes] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [documentosData, setDocumentosData] = useState([]);
  const userId = sessionStorage.getItem("userId");
  const customHeaderStyle = { backgroundColor: "#e0dddc" };
  const [encodedFile, setEncodedFile] = useState(null);
  const [totalEmitidos, setTotalEmitidos] = useState(0);
  const [documentosRegistrados, setDocumentosRegistrados] = useState(0);
  const [documentosPorRegistrar, setDocumentosPorRegistrar] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [folioTemp, setFolioTemp] = useState({ id: null, folio: null });
  const [detalleVisible, setDetalleVisible] = useState(false);
  const [detalleCobro, setDetalleCobro] = useState([]);

  const anios = [
    { label: "2020", value: 2020 },
    { label: "2021", value: 2021 },
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
  ];

  const meses = [
    { label: "Enero", mes: "01" },
    { label: "Febrero", mes: "02" },
    { label: "Marzo", mes: "03" },
    { label: "Abril", mes: "04" },
    { label: "Mayo", mes: "05" },
    { label: "Junio", mes: "06" },
    { label: "Julio", mes: "07" },
    { label: "Agosto", mes: "08" },
    { label: "Septiembre", mes: "09" },
    { label: "Octubre", mes: "10" },
    { label: "Noviembre", mes: "11" },
    { label: "Diciembre", mes: "12" },
  ];

  useEffect(() => {
    const fetchEmpresas = async () => {
      const endpoint = `${baseUrl}getEmpresasByUsuario?idUsuario=${userId}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        });

        const data = await response.json();

        if (!response.ok || !data.respuesta) {
          throw new Error(data.mensaje || "Error al obtener las empresas.");
        }

        // Mapea las empresas para que tengan label y value
        const empresasFetched = data.empresas.map((empresa) => ({
          label: empresa.nombre, // Nombre de la empresa como label
          id: empresa.id, // ID de la empresa como value
        }));

        setEmpresas(empresasFetched);
      } catch (error) {
        console.error("Error fetching empresas:", error);
      }
    };

    fetchEmpresas();
  }, [baseUrl, userId]);

  useEffect(() => {
    const fetchTiposDocumento = async () => {
      const endpoint = `${baseUrl}getTiposDocumento`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        });

        const data = await response.json();

        if (!response.ok || !data.respuesta) {
          throw new Error(
            data.mensaje || "Error al obtener tipos de documento."
          );
        }

        // Mapea los tipos de documento para que tengan label y value
        const tiposDocumentoFetched = data.tiposDocumento.map((tipo) => ({
          label: tipo.descripcion,
          codigo: tipo.codigo,
        }));

        setDocumentos(tiposDocumentoFetched);
      } catch (error) {
        console.error("Error fetching tipos de documento:", error);
      }
    };

    fetchTiposDocumento();
  }, [baseUrl]);

  const [importeTotal, setImporteTotal] = useState(0);
  const [documentosEncontrados, setDocumentosEncontrados] = useState(0);

  const buscarDocumentos = async () => {
    if (!empresa || !anio || !mes || !documento) {
      alert("Por favor, seleccione todos los campos antes de buscar.");
      return;
    }

    try {
      setLoading(true);
      const endpoint = `${baseUrl}obtenerDocumentos?idEmpresa=${empresa}&anio=${anio}&mes=${mes}&tipoDocumento=${documento}`;

      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      const data = await response.json();

      if (!response.ok || data.respuesta !== true) {
        throw new Error(data.mensaje || "Error al obtener los documentos.");
      }

      if (!data.documentos || !Array.isArray(data.documentos)) {
        throw new Error(
          "La respuesta no contiene una lista de documentos válida."
        );
      }

      const documentosFetched = data.documentos.map((doc) => {
        const totalIva =
          doc.tipoDocumento.toLowerCase() === "factura afecta"
            ? Math.floor(doc.total * 0.19)
            : 0;

        // Obtener fecha del primer adjunto si existe
        const fechaDocumento =
          doc.adjuntos && doc.adjuntos.length > 0
            ? doc.adjuntos[0].fecha
            : "Documento no disponible";

        return {
          id_documento: doc.idDocumento,
          empresa: doc.descripcionEmpresa,
          cliente: doc.nombreCliente,
          rut_cliente: doc.rutCliente,
          documento: doc.tipoDocumento,
          n_documento: doc.folio,
          fecha_documento: fechaDocumento,
          total_neto: doc.total,
          total_iva: totalIva,
          total_documento: doc.total + totalIva,
          adjuntos: doc.adjuntos || [],
          detalleCobro: doc.detallesCobro || [],
        };
      });

      const totalImporte = documentosFetched.reduce(
        (sum, doc) => sum + doc.total_documento,
        0
      );

      const totalEmitidos = documentosFetched
        .filter((doc) => doc.adjuntos && doc.adjuntos.length > 0)
        .reduce((sum, doc) => sum + doc.total_documento, 0);

      // Contar documentos con y sin adjuntos
      const countRegistrados = documentosFetched.filter(
        (doc) => doc.adjuntos && doc.adjuntos.length > 0
      ).length;
      const countPorRegistrar = documentosFetched.filter(
        (doc) => !doc.adjuntos || doc.adjuntos.length === 0
      ).length;

      setImporteTotal(totalImporte);
      setTotalEmitidos(totalEmitidos);
      setDocumentosRegistrados(countRegistrados);
      setDocumentosPorRegistrar(countPorRegistrar);
      setDocumentosEncontrados(documentosFetched.length);
      setDocumentosData(documentosFetched);
      setShowTable(true);
    } catch (error) {
      console.error("Error fetching documentos:", error);
      alert("Error al obtener los documentos: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const onEditorValueChange = (props, value) => {
    const numericValue = value.replace(/\D/g, "");

    let updatedData = [...documentosData];

    if (updatedData[props.rowIndex] && props.field) {
      updatedData[props.rowIndex][props.field] = numericValue;
      setDocumentosData(updatedData);
      setFolioTemp({ id: props.rowData.id_documento, folio: numericValue });
    }
  };

  const inputTextEditor = (props) => {
    console.log("Value in InputText:", props.rowData[props.field]);
    return (
      <InputText
        type="text"
        value={props.rowData[props.field]}
        onChange={(e) => onEditorValueChange(props, e.target.value)}
        onBlur={actualizarFolioDocumento}
      />
    );
  };

  const actualizarFolioDocumento = async () => {
    if (folioTemp.id && folioTemp.folio !== null) {
      try {
        const endpoint = `${baseUrl}actualizarFolio?id_documento=${folioTemp.id}&folio=${folioTemp.folio}`;
        const response = await fetch(endpoint, {
          method: "POST",
          credentials: "include",
          redirect: "follow",
        });

        const data = await response.json();

        if (!response.ok || !data.respuesta) {
          throw new Error(data.mensaje || "Error al actualizar el folio.");
        }
        buscarDocumentos();
        alert("Folio actualizado correctamente");
      } catch (error) {
        console.error("Error actualizando el folio:", error);
        alert("Error al actualizar el folio: " + error.message);
      }
    }
  };

  /////////////////////////////////////////////// LOGICA PARA GUARDAR LOS DOCUMENTOS SUBIDOS A LA APP. ///////////////////////////////////////////////

  const documentoSubidoTemplate = (rowData) => {
    const tieneAdjunto = rowData.adjuntos && rowData.adjuntos.length > 0;
    const tipoDocumento = rowData.documento.toLowerCase();

    if (
      tipoDocumento === "factura afecta" ||
      tipoDocumento === "factura exenta"
    ) {
      if (!tieneAdjunto) {
        return (
          <FileUpload
            mode="basic"
            chooseLabel="adjuntar"
            uploadLabel="Subir Archivo"
            cancelLabel="Cancelar"
            multiple={false}
            customUpload
            uploadHandler={(e) => handleFileUpload(e, rowData)}
            disabled={isUploading}
          />
        );
      } else {
        const adjunto = rowData.adjuntos[0];
        return (
          <Button
            label="Ver Archivo"
            icon="pi pi-download"
            className="p-button-success"
            onClick={() => bajarArchivo(rowData.id_documento, adjunto.nombre)}
            disabled={isUploading}
          />
        );
      }
    } else if (tipoDocumento === "nota de cobro") {
      return (
        <Button
          label="Generar PDF"
          icon="pi pi-file-pdf"
          className="p-button-warning"
          onClick={() => exportToPDF(rowData)}
          disabled={isUploading}
        />
      );
    }

    return null;
  };

  const detalleCobroTemplate = (rowData) => {
    console.log(rowData);
    return (
      <Button
        icon="pi pi-exclamation-circle"
        className="p-rounded-icon-only p-button-rounded p-button-info"
        onClick={() => verDetalle(rowData)}
        style={{ marginRight: "3px" }}
      />
    );
  };

  const verDetalle = (rowData) => {
    setDetalleCobro(rowData);
    setDetalleVisible(true);
  };

  const handleFileUpload = async (event, rowData) => {
    if (!isSaved) {
    }
    const file = event.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = btoa(e.target.result);

        const contentType = file.type;
        const fileName = file.name;
        const fileSize = file.size;

        setEncodedFile(base64Data);
        subirArchivo(
          JSON.stringify({
            contentType: contentType,
            fileName: fileName,
            base64: base64Data,
            fileSize: fileSize,
            idDocumento: rowData.id_documento,
          })
        );
      };

      reader.readAsBinaryString(file);
    }
  };

  const subirArchivo = async (archivo) => {
    const endpoint = baseUrl + "adjuntarArchivoDocumento";
    try {
      setIsUploading(true);
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        body: archivo,
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      if (data.respuesta) {
        alert("Archivo subido exitosamente");

        setFolioTemp({ id: null, folio: null });
        await buscarDocumentos();
      } else {
        alert("Hubo un problema al subir el archivo");
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const bajarArchivo = async (idDocumento, nombreArchivo) => {
    setLoading(true);
    try {
      const response = await fetch(
        baseUrl + "descargar/adjunto/documento?id=" + idDocumento,
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to download file. Status: ${response.status}`);
      }
      const blob = await response.blob(); // Get the response body as Blob

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      const fileName =
        response.headers.get("Content-Disposition")?.split("=")[1] ||
        nombreArchivo;

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setLoading(false);
  };

  ////////////////////////////////// Creacion de PDF para las notas de cobro //////////////////////////////////

  const exportToPDF = (rowData) => {
    const pdf = new jsPDF("p", "mm", "a4");

    const marginLeft = 10;
    const marginTop = 10;

    // Datos de la nota de cobro
    const clienteNombre = rowData.cliente;
    const clienteRUT = rowData.rut_cliente;
    const empresaNombre = rowData.empresa;
    const empresaRUT = rowData.rut_empresa; // Debes asegurarte de que este campo esté disponible
    const inicioPago = rowData.detalleCobro[0]?.inicioPago || "No disponible";
    const finPago = rowData.detalleCobro[0]?.finPago || "No disponible";
    const totalDocumento = rowData.total_documento.toLocaleString();

    // Logo
    const logoUrl = "/logoCBQ.png";
    pdf.addImage(logoUrl, "PNG", marginLeft, marginTop, 30, 20);

    pdf.setDrawColor(0, 0, 0);

    // Recuadro "Valor actual UF" (debajo del de Mora)
    pdf.rect(150, marginTop + 10, 50, 10);
    pdf.text("Valor UF: $37,940", 152, marginTop + 17);

    // Título "Nota de Cobro"
    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");
    pdf.text("Nota de cobro", 85, marginTop + 40);

    // Información del cliente (izquierda)
    pdf.setFont("times", "normal");
    pdf.setFontSize(12);
    pdf.text(`Srs: ${clienteNombre}`, marginLeft, marginTop + 50);
    pdf.text(`RUT: ${clienteRUT}`, marginLeft, marginTop + 55);

    // Información de la empresa (derecha)
    pdf.text(`Empresa Cobradora: ${empresaNombre}`, 110, marginTop + 50);
    pdf.text(`RUT Empresa: ${empresaRUT}`, 110, marginTop + 55);
    pdf.text(`Inicio Periodo de Cobro: ${inicioPago}`, 110, marginTop + 60);
    pdf.text(`Fin Periodo de Cobro: ${finPago}`, 110, marginTop + 65);

    // Recuadro para total cobro
    pdf.setLineWidth(0.5);
    pdf.rect(marginLeft, marginTop + 70, 90, 10); // Rectángulo para el total
    pdf.text(
      `Total Cobro General: $ ${totalDocumento}`,
      marginLeft + 2,
      marginTop + 77
    );

    pdf.rect(110, marginTop + 70, 90, 10); // Rectángulo para el total UF
    pdf.text("Total Cobro General UF: 5,05", 112, marginTop + 77);

    // Tabla de Detalle Cobro Servicios
    pdf.setFontSize(12);
    pdf.text("Detalle Cobro Servicios", marginLeft, marginTop + 90);

    // Datos de la tabla de detalle
    const columns = ["ITEM", "CANTIDAD", "UNIDAD MEDIDA", "UNITARIO", "TOTAL"];
    const rows = rowData.detalleCobro.map((detalle) => [
      detalle.glosa, // Descripción del item
      detalle.cantidad.toString(), // Cantidad
      detalle.unidadMedida.toString(), // Cantidad
      detalle.unitario.toLocaleString(), // Valor unitario
      (detalle.cantidad * detalle.unitario).toLocaleString(), // Total
    ]);

    pdf.autoTable({
      startY: marginTop + 95, // Posición inicial de la tabla
      head: [columns],
      body: rows,
      styles: { halign: "center", valign: "middle" }, // Centrar el contenido
      headStyles: { fillColor: [200, 200, 200] }, // Fondo gris para el header
    });

    // Recuadro para Observaciones
    const observacionesTop = marginTop + 150;
    const observacionesHeight = 30;
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(marginLeft, observacionesTop, 190, observacionesHeight);

    // Texto de Observaciones
    pdf.setFontSize(14);
    pdf.text("Observaciones:", marginLeft + 2, observacionesTop + 5);
    pdf.setFontSize(11);
    pdf.text(
      "Deuda pendiente corresponde a la nota de cobro 1392, por un total de $72.930. \nRecordamos ponerse al día con los pagos anteriores",
      marginLeft + 2,
      observacionesTop + 11
    );

    // Abrir el PDF en una nueva ventana
    const pdfData = pdf.output("bloburl");
    window.open(pdfData);
  };

  const formatNumber = (number) => {
    // Convertimos el número a string y separamos la parte entera de la decimal
    const [integerPart, decimalPart] = number.toString().split(".");

    // Formateamos la parte entera para que tenga puntos como separador de miles
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Si hay parte decimal, truncamos a dos cifras y luego la unimos con una coma
    const formattedDecimal = decimalPart ? decimalPart.substring(0, 1) : null;

    // Retornamos el número con el formato correcto
    return formattedDecimal
      ? `${formattedInteger},${formattedDecimal}`
      : formattedInteger;
  };

  return (
    <div>
      <h3>Documentos</h3>
      <div className="grid p-align-center p-justify-center">
        <div
          className="p-fluid card grid md:col-12 col-12"
          style={{ marginTop: "5px", marginLeft: "5px" }}
        >
          <div className="p-fluid md:col-12 col-12">
            <h5 style={{ marginTop: "5px" }}>Ingrese la información</h5>
          </div>
          <div className="p-fluid md:col-3 col-12">
            <Dropdown
              value={empresa}
              options={empresas}
              onChange={(e) => setEmpresa(e.value)}
              optionLabel="label"
              optionValue="id"
              placeholder="Seleccione Empresa"
            />
          </div>
          <div className="md:col-3 col-12">
            <Dropdown
              value={anio}
              options={anios}
              onChange={(e) => setAnio(e.value)}
              placeholder="Seleccione Año"
            />
          </div>
          <div className="md:col-3 col-12">
            <Dropdown
              value={mes}
              options={meses}
              onChange={(e) => setMes(e.value)}
              optionLabel="label"
              optionValue="mes"
              placeholder="Seleccione Mes"
            />
          </div>

          <div className="md:col-3 col-12">
            <Dropdown
              value={documento}
              options={documentos}
              onChange={(e) => setDocumento(e.value)}
              optionLabel="label"
              optionValue="codigo"
              placeholder="Seleccione Documento"
            />
          </div>

          <div className="p-text-center md-col:3 col-3">
            <Button label="Buscar" onClick={buscarDocumentos} />
          </div>
        </div>
      </div>

      {showTable && (
        <div
          className="p-fluid grid md:col-12 col-12"
          style={{ marginTop: "5px" }}
        >
          <div className="md:col-2 col-12">
            <div className="card">
              <span className="block text-500 font-medium mb-3">Valor UF:</span>{" "}
              <h1>$37.836</h1>
              <span className="text-500">
                Periodo {anio}-{mes}
              </span>
            </div>
          </div>

          <div className="md:col-2 col-12">
            <div className="card">
              <span className="block text-500 font-medium mb-3">Importe:</span>{" "}
              <h1>${formatNumber(importeTotal)}</h1>
              <span className="text-500">total</span>
            </div>
            <div></div>
          </div>

          <div className="md:col-2 col-12">
            <div className="card">
              <span className="block text-500 font-medium mb-3">Emitidos:</span>{" "}
              <h1>$ {formatNumber(totalEmitidos)}</h1>
              <span className="text-500">
                Por emitir: ${(importeTotal - totalEmitidos).toLocaleString()}
              </span>
            </div>
            <div></div>
          </div>

          <div className="md:col-2 col-12">
            <div className="card">
              <span className="block text-500 font-medium mb-3">
                Documentos
              </span>
              <h1>{documentosEncontrados}</h1>
              <span className="text-500">documentos encontrados</span>
            </div>
          </div>

          <div className="md:col-2 col-12">
            <div className="card">
              <span className="block text-500 font-medium mb-3">
                Documentos Registrados
              </span>
              <h1>{documentosRegistrados}</h1>
              <span className="text-500">con archivo adjunto</span>
            </div>
          </div>

          <div className="md:col-2 col-12">
            <div className="card">
              <span className="block text-500 font-medium mb-3">
                Documentos por Registrar
              </span>
              <h1>{documentosPorRegistrar}</h1>
              <span className="text-500">sin archivo adjunto</span>
            </div>
          </div>
        </div>
      )}

      {showTable && (
        <div className="p-mt-3">
          <DataTable
            className="p-datatable-gridlines"
            value={documentosData}
            paginator
            rows={10}
          >
            <Column
              field="id_documento"
              header="Id"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por ID"
            />
            <Column
              field="empresa"
              header="Empresa"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por Empresa"
            />
            <Column
              field="cliente"
              header="Cliente"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por Cliente"
            />
            <Column
              field="rut_cliente"
              header="RUT Cliente"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por RUT"
            />
            <Column
              field="documento"
              header="Documento"
              headerStyle={customHeaderStyle}
            />
            <Column
              field="n_documento"
              header="N° Documento"
              editor={(props) => inputTextEditor(props)}
              body={(rowData) => (
                <InputText
                  value={rowData.n_documento}
                  onChange={(e) => onEditorValueChange(props, e.target.value)}
                  onBlur={actualizarFolioDocumento}
                />
              )}
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por N° Documento"
            />
            <Column
              field="fecha_documento"
              header="Fecha Documento"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por Fecha"
            />
            <Column
              field="total_neto"
              header="Total Neto"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por Total Neto"
              filterMatchMode="gte"
            />
            <Column
              field="total_iva"
              header="Total IVA"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por Total IVA"
              filterMatchMode="gte"
            />
            <Column
              field="total_documento"
              header="Total Documento"
              headerStyle={customHeaderStyle}
              filter
              filterPlaceholder="Buscar por Total Documento"
              filterMatchMode="gte"
            />
            <Column
              field="adjuntos"
              header="Acción"
              body={(rowData) => (
                <>
                  {detalleCobroTemplate(rowData.detalleCobro)}
                  {documentoSubidoTemplate(rowData)}
                </>
              )}
              headerStyle={customHeaderStyle}
            />
          </DataTable>
        </div>
      )}
      <Dialog
        header="Detalle del Cobro"
        visible={detalleVisible}
        style={{ width: "50vw" }}
        onHide={() => setDetalleVisible(false)}
      >
        <DataTable value={detalleCobro}>
          <Column field="glosa" header="Descripción Item" />
          <Column field="cantidad" header="Cantidad" />
          <Column field="unidadMedida" header="Unidad Medida" />
          <Column field="unitario" header="Valor Unitario" />
          <Column field="inicioPago" header="Fecha inicio pago" />
          <Column field="finPago" header="Ultima fecha pago" />
        </DataTable>
      </Dialog>
    </div>
  );
};

export default Documentos;
